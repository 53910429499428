
import { SectionWhyChooseUs3Inner } from "../../home/index2/section-why-choose-us3";

function SectionWhyChooseUs5() {
    return (
        <>
            <div className="section-full  p-t100 p-b0 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUs3Inner />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionWhyChooseUs5;