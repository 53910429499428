import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage5() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/social.jpeg"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Social Media Marketing and Account Management
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title">Why Choose Us</div>
                <h2 className="sx-title">
                  Social Media Marketing and Account Management
                </h2>
                <div className="sx-head-l-title">
                  <p>
                    At World Wide Dispatch Management, we harness the power of
                    social media to help businesses thrive in today's digital
                    landscape. Our Social Media Marketing and Account Management
                    services are tailored to help you connect with your
                    audience, build brand awareness, and drive engagement.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Expertise:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Customized Social Media Strategy:</strong> We
                          develop tailored strategies to achieve your business
                          goals, targeting the right audience and industry
                          niche.
                        </li>
                        <li>
                          <strong>Content Creation and Curation:</strong> Our
                          skilled content creators develop high-quality content
                          that resonates with your audience and reflects your
                          brand identity.
                        </li>
                        <li>
                          <strong>Community Management:</strong> We monitor and
                          manage your social media accounts daily, fostering
                          meaningful interactions and strong relationships with
                          your followers.
                        </li>
                        <li>
                          <strong>Paid Advertising Campaigns:</strong> We design
                          and execute targeted campaigns to reach your desired
                          audience and drive conversions.
                        </li>
                        <li>
                          <strong>Influencer Partnerships:</strong> We
                          collaborate with relevant influencers to amplify your
                          brand reach and credibility.
                        </li>
                        <li>
                          <strong>Analytics and Reporting:</strong> We provide
                          comprehensive insights into your social media
                          performance, helping you understand what works and
                          what doesn't.
                        </li>
                        <li>
                          <strong>
                            Social Media Training and Consultation:
                          </strong>{" "}
                          We empower you and your team to take control of your
                          social media presence and drive success.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/SSM.webp"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="row pt-4 mt-4">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/sm1.webp"
                        alt="image"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Results-Driven Approach:</strong> We deliver
                          measurable results aligned with your business
                          objectives.
                        </li>
                        <li>
                          <strong>Quality and Consistency:</strong> We maintain
                          high standards in all aspects of our social media
                          marketing efforts.
                        </li>
                        <li>
                          <strong>Transparency and Communication:</strong> We
                          keep you informed every step of the way, providing
                          regular updates and recommendations.
                        </li>
                        <li>
                          <strong>Ethical Practices:</strong> We adhere to
                          ethical principles and industry best practices,
                          respecting user privacy and platform guidelines.
                        </li>
                        <li>
                          <strong>Continuous Improvement:</strong> We adapt to
                          changing trends, algorithms, and consumer behaviors,
                          ensuring your social media presence remains relevant
                          and effective.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage5;
