import { NavLink } from "react-router-dom";
import ItodoImage from "../../../elements/itodo-img";

function SectionServices1() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-our-services-outer sx-bg-light">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head center">
                        <div className="sx-head-s-title">Our Services</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">Comprehensive Dispatch and Marketing Solutions</h2>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="sx-our-services-bx">
                            <div className="row">
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/texi2.webp" alt="image" style={{borderRadius:'50%'}} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte">Taxi Dispatch Outsourcing Solutions</h4>
                                                <p>Efficient taxi dispatch solutions to keep your fleet running smoothly and improve customer satisfaction.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Taxi-Dispatch-Outsourcing-Solutions" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/limo.jpeg" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte">Limo Dispatch Outsourcing Solutions</h4>
                                                <p>Luxury limo dispatch services to ensure a seamless experience for your clients and enhance your operations.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Limo-Dispatch-Outsourcing-Solutions" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/neom.webp" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte">Non-Emergency Medical Transportation (NEMT) Dispatch Outsourcing Solutions</h4>
                                                <p>Reliable NEMT dispatch services to ensure safe and timely transportation for patients.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Non-Emergency-Medical-Transportation-(NEMT)-Dispatch-Outsourcing" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/truck3.webp" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte">Truck Dispatch Services</h4>
                                                <p>Comprehensive truck dispatch services for efficient logistics and improved fleet management.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Truck-Dispatch-Services" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/website1.webp" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte">Website Development Services</h4>
                                                <p>Creating innovative and user-centric websites to enhance your online presence and drive business growth.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Website-Development-Services" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/sm2.webp" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte">Social Media Marketing and Account Management</h4>
                                                <p>Harnessing the power of social media to grow your business and engage with your audience effectively.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Social-Media-Marketing-and-Account-Management" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/call1.webp" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte">Voice Service for Inbound and Outbound Call Center Services</h4>
                                                <p>Providing exceptional inbound and outbound call center services to manage your customer interactions.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Voice-Service-for-Inbound-and-Outbound-Call-Center-Services" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionServices1;
