import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage3() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/truck.png"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Truck Dispatch Services
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title">Why Choose Us</div>
                <h2 className="sx-title">Truck Dispatch Services</h2>
                <div className="sx-head-l-title">
                  <p>
                    At Worldwide Dispatch Management, we recognize the critical
                    role that efficient truck dispatch plays in the logistics
                    and transportation industry. Our Truck Dispatch Services are
                    designed to support trucking companies, freight brokers, and
                    logistics providers in optimizing their operations,
                    maximizing fleet utilization, and ensuring timely delivery
                    of goods.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Expertise:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Comprehensive Dispatch Solutions:</strong> Our
                          experienced dispatch professionals manage all aspects
                          of truck dispatch, from load assignment to route
                          optimization and driver communication.
                        </li>
                        <li>
                          <strong>Advanced Dispatch Software:</strong> Our
                          state-of-the-art dispatch software features load
                          tracking, automated scheduling, and real-time
                          communication with drivers, streamlining dispatch
                          operations and improving fleet efficiency.
                        </li>
                        <li>
                          <strong>Customizable Solutions:</strong> We tailor our
                          dispatch solutions to meet the specific needs and
                          preferences of your business, whether you operate a
                          small fleet or manage a large-scale logistics
                          operation.
                        </li>
                        <li>
                          <strong>Load Optimization:</strong> Our dispatch
                          specialists analyze available loads and match them
                          with the most suitable trucks, reducing downtime and
                          increasing profitability.
                        </li>
                        <li>
                          <strong>Real-time Visibility:</strong> Our
                          comprehensive tracking and monitoring capabilities
                          provide real-time visibility into your fleet
                          operations, enabling you to track the status and
                          location of every truck, monitor load progress, and
                          receive alerts for any issues or delays.
                        </li>
                        <li>
                          <strong>Efficient Communication:</strong> Our
                          integrated communication tools facilitate seamless
                          communication between dispatchers, drivers, and
                          customers, ensuring clear and timely communication at
                          every stage of the transportation process.
                        </li>
                        <li>
                          <strong>Compliance Management:</strong> Our compliance
                          management tools help you track driver hours of
                          service, manage electronic logs, and ensure adherence
                          to DOT regulations, minimizing the risk of violations
                          and penalties.
                        </li>
                        <li>
                          <strong>Responsive Support:</strong> Our dedicated
                          support team is available 24/7 to assist you with any
                          inquiries or concerns you may have.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/truck2.webp"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage src="images/services/images/truck3.webp" alt="image" />
                    </div>
                    <div className="col-lg-6 col-md-6 pt-4">
                      <h3>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Reliability</strong>
                        </li>
                        <li>
                          <strong>Safety</strong>
                        </li>
                        <li>
                          <strong>Customer Satisfaction</strong>
                        </li>
                        <li>
                          <strong>Continuous Improvement</strong>
                        </li>
                        <li>
                          <strong>Transparency</strong>
                        </li>
                      </ul>
                      <p>
                        By partnering with us, you can streamline your
                        operations, optimize fleet performance, and deliver
                        exceptional service to your customers. Join us today and
                        experience the difference firsthand.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage3;
