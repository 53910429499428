import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage6() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/resize voice service header.webp"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Voice Service for Inbound and Outbound Call Center Services
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title">Why Choose Us</div>
                <h2 className="sx-title">
                  Voice Service for Inbound and Outbound Call Center Services
                </h2>
                <div className="sx-head-l-title">
                  <p>
                    At World wide dispatch management, we specialize in
                    delivering exceptional voice services for inbound and
                    outbound call center operations. Our experienced team and
                    cutting-edge technology provide comprehensive solutions
                    tailored to meet the unique needs of businesses across
                    industries.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Expertise:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Inbound Call Center Services:</strong> We
                          handle incoming calls with professionalism and
                          efficiency, offering customer support, help desk
                          support, and order processing.
                        </li>
                        <li>
                          <strong>Outbound Call Center Services:</strong> We
                          conduct outbound calls to achieve various objectives,
                          including sales and telemarketing, appointment
                          setting, and market research.
                        </li>
                        <li>
                          <strong>Professional Voice Agents:</strong> Our
                          trained call center agents excel in effective
                          communication, active listening, and problem-solving,
                          ensuring positive customer experiences.
                        </li>
                        <li>
                          <strong>Multi-channel Support:</strong> We offer
                          seamless support across email, chat, and social media,
                          providing an integrated customer experience.
                        </li>
                        <li>
                          <strong>Scalability and Flexibility:</strong> Our
                          voice services adapt to fluctuations in call volume,
                          seasonal demands, or business growth, ensuring optimal
                          service levels.
                        </li>
                        <li>
                          <strong>Quality Assurance:</strong> We maintain
                          rigorous quality assurance processes to monitor call
                          quality, script adherence, and regulatory compliance,
                          ensuring consistently high standards.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/voice service.webp"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="row pt-4 mb-4">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/call3.webp"
                        alt="image"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Customer Satisfaction:</strong> We strive to
                          exceed customer expectations with timely, accurate,
                          and helpful assistance.
                        </li>
                        <li>
                          <strong>Data Security and Privacy:</strong> We
                          safeguard sensitive information with strict protocols
                          and trained agents, adhering to regulations like GDPR
                          and HIPAA.
                        </li>
                        <li>
                          <strong>Continuous Training and Development:</strong>{" "}
                          Our agents undergo regular coaching, feedback, and
                          upskilling to stay updated on industry best practices.
                        </li>
                        <li>
                          <strong>Transparent Reporting and Analytics:</strong>{" "}
                          We provide clear reporting and analytics to track
                          KPIs, measure service performance, and optimize call
                          center operations.
                        </li>
                        <li>
                          <strong>Collaborative Partnership:</strong> We work
                          closely with clients to achieve shared goals, offering
                          personalized support, proactive communication, and
                          strategic guidance.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage6;
