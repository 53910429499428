import { NavLink } from "react-router-dom";
import ItodoImage from "../../../elements/itodo-img";
import "./SectionServices3.css"; // Import the CSS file

function SectionServices3() {
    return (
        <>
            <div className="section-full p-t50 p-b5 sx-why-choose2-outer sx-bg-light" style={{
                backgroundColor: '#291f3b',
            }}>
                <div className="container">
                    <div className="large-title-block sx-all-white">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="section-head left">
                                    <div className="sx-head-s-title">Our Services</div>
                                    <div className="sx-head-l-title">
                                        <h2 className="sx-title">What We Are Expert At Our Special Services.</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="large-title-info">
                                    <p>World Wide Dispatch Management is a premier provider of taxi dispatch services, and we're here to help you maximize your business revenue with our 24/7 services to ensure that your drivers are always on the move and that your customers receive timely and professional services.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-content" style={{ color: '#000' }}>
                        <div className="owl-carousel sx-why-choose-carousel2 m-b10">
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/slide1.png" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Taxi-Dispatch-Outsourcing-Solutions" className="sx-why-ch-category">Taxi Dispatch</NavLink>
                                            <NavLink to="/services/Taxi-Dispatch-Outsourcing-Solutions"><h4 className="sx-tilte">Taxi Dispatch Outsourcing Solutions</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/Limo.webp" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Limo-Dispatch-Outsourcing-Solutions" className="sx-why-ch-category">Limo Dispatch</NavLink>
                                            <NavLink to="/services/Limo-Dispatch-Outsourcing-Solutions"><h4 className="sx-tilte">Limo Dispatch Outsourcing Solutions</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/NonEmergency.png" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Non-Emergency-Medical-Transportation-(NEMT)-Dispatch-Outsourcing" className="sx-why-ch-category">Non-Emergency Medical Transportation</NavLink>
                                            <NavLink to="/services/Non-Emergency-Medical-Transportation-(NEMT)-Dispatch-Outsourcing"><h4 className="sx-tilte">Non-Emergency Medical Transportation (NEMT) Dispatch Outsourcing Solutions</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/truck.png" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Truck-Dispatch-Services" className="sx-why-ch-category">Truck Dispatch</NavLink>
                                            <NavLink to="/services/Truck-Dispatch-Services"><h4 className="sx-tilte">Truck Dispatch Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/web development.webp" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Website-Development-Services" className="sx-why-ch-category">Website Development </NavLink>
                                            <NavLink to="/services/Website-Development-Services"><h4 className="sx-tilte">Website Development Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/social.jpeg" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Social-Media-Marketing-and-Account-Management" className="sx-why-ch-category">Social Media </NavLink>
                                            <NavLink to="/services/Social-Media-Marketing-and-Account-Management"><h4 className="sx-tilte">Social Media Marketing and Account Management</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/voice.jpeg" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Voice-Service-for-Inbound-and-Outbound-Call-Center-Services" className="sx-why-ch-category">Voice Service</NavLink>
                                            <NavLink to="/services/Voice-Service-for-Inbound-and-Outbound-Call-Center-Services"><h4 className="sx-tilte">Voice Service for Inbound and Outbound Call Center Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionServices3;
