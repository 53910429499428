import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import SectionTestimonials2 from "./section-testimonials2";

export function SectionWhyChooseUs2Inner() {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <div className="sx-ab4-img-outer">
                            <div className="sx-ab4-media">
                                <ItodoImage src="images/services/images/voice service.webp" alt="media" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title">Why Choose World Wide Dispatch Management?</h2>
                        <p>At World Wide Dispatch Management, we are dedicated to streamlining your operations, improving efficiency, and delivering exceptional service. Here’s why you should choose us:</p>
                        <ul className="sx-arrow-circle-checked">
                            <li>Comprehensive dispatch solutions tailored to your needs</li>
                            <li>24/7 customer support and service availability</li>
                            <li>Experienced and professional team</li>
                            <li>Advanced technology and tools for efficient management</li>
                            <li>Competitive pricing and transparent fees</li>
                        </ul>
                        <NavLink to="/about-us" className="site-button sx-btn-primary icon sx-btn-lg">
                            <i className="fa fa-long-arrow-right" />
                            Learn More
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionWhyChooseUs2() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUs2Inner />
                        </div>
                        {/* Testimonial Section */}
                        <SectionTestimonials2 />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionWhyChooseUs2;
