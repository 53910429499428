import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage1() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/resize limo header.webp"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Limo Dispatch Outsourcing Solutions
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title">Why Choose Us</div>
                <h2 className="sx-title">
                  Limo Dispatch Outsourcing Solutions
                </h2>
                <div className="sx-head-l-title">
                  <p>
                    At Worldwide Dispatch Management, we specialize in catering
                    to the unique needs of luxury transportation businesses. Our
                    Limo Dispatch Outsourcing solutions are expertly tailored to
                    enhance the operations of limousine companies, chauffeur
                    services, and high-end transportation providers worldwide.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Offerings:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Premium Dispatch Services:</strong> Our
                          experienced dispatch professionals deliver top-tier
                          service, ensuring flawless ride execution and utmost
                          professionalism.
                        </li>
                        <li>
                          <strong>Sophisticated Dispatch Software:</strong>{" "}
                          Leverage our cutting-edge software, designed
                          specifically for the luxury transportation industry,
                          to streamline bookings, coordinate with chauffeurs,
                          and optimize dispatch operations.
                        </li>
                        <li>
                          <strong>Tailored Solutions:</strong> We understand the
                          unique requirements of luxury transportation
                          businesses and offer fully customizable solutions to
                          accommodate your specific needs, whether it's
                          executive travel, event transportation, or VIP
                          services.
                        </li>
                        <li>
                          <strong>Scalable Infrastructure:</strong> Our
                          solutions are designed to scale with your business,
                          whether you operate a small fleet or manage a
                          large-scale limousine service.
                        </li>
                        <li>
                          <strong>Cost-Effective Outsourcing:</strong> Reduce
                          overhead costs by up to 70% by outsourcing your limo
                          dispatch operations to us, while maintaining high
                          service quality and reliability.
                        </li>
                        <li>
                          <strong>Impeccable Customer Experience:</strong> We
                          deliver exceptional experiences to your clients
                          through efficient dispatch processes and attention to
                          detail, fostering long-term loyalty.
                        </li>
                        <li>
                          <strong>Real-time Visibility:</strong> Gain
                          comprehensive tracking and monitoring capabilities to
                          monitor vehicle status, track driver locations, and
                          respond proactively to any issues.
                        </li>
                        <li>
                          <strong>Responsive Support:</strong> Our dedicated
                          support team is available 24/7 to assist with
                          technical assistance, operational guidance, or any
                          inquiries.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage src="images/services/images/limo(1).webp" alt="image" />
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage src="images/services/images/limo1.webp" alt="image" />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Excellence:</strong> We understand the
                          importance of reliability in the transportation
                          industry. Our dispatch solutions are built to deliver
                          consistent and dependable service, ensuring that your
                          vehicles are always where they need to be, when they
                          need to be there.
                        </li>
                        <li>
                          <strong>Confidentiality and Discretion:</strong> Your
                          data security and privacy are our top priorities. We
                          adhere to industry best practices and employ robust
                          security measures to safeguard your sensitive
                          information.
                        </li>
                        <li>
                          <strong>Partnership:</strong> We build long-term
                          relationships based on trust, collaboration, and
                          mutual success, invested in helping you achieve your
                          business goals.
                          <br></br>
                          Partner with Worldwide Dispatch Management today and
                          experience the difference in elevating your service
                          standards, optimizing operations, and delivering
                          unforgettable experiences to your clients.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage1;
