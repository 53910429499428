import Banner from "../../sections/common/banner";
import SectionServices1 from "../../sections/home/index/section-services1";
import { bannerData } from "../../../globals/banner";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import SectionAboutCompany2 from "../../sections/home/index/section-about-company2";
import SectionWhyChooseUs from "../../sections/company/about/section-why-choose-us";

function AboutUsPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            <Banner _data={bannerData.about} />
            <SectionAboutCompany2 />
            <SectionServices1 />
            <SectionWhyChooseUs />
        </>
    )
}

export default AboutUsPage;