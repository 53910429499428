import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage2() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="/images/services/images/neom2.webp"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Non-Emergency Medical Transportation (NEMT) Dispatch Outsourcing
                Solutions
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title">Why Choose Us</div>
                <h2 className="sx-title">
                  Non-Emergency Medical Transportation (NEMT) Dispatch
                  Outsourcing Solutions
                </h2>
                <div className="sx-head-l-title">
                  <p>
                    At World Wide Dispatch Management we recognize the vital
                    importance of non-emergency medical transportation in
                    ensuring access to healthcare for individuals with mobility
                    challenges. Our NEMT Dispatch Outsourcing solutions are
                    designed to support healthcare providers, medical
                    facilities, and transportation companies in efficiently
                    coordinating non-emergency medical trips for patients
                    worldwide.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Expertise:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Specialized Dispatch Services:</strong> Our
                          team of trained dispatch specialists prioritizes
                          patient safety and comfort, managing non-emergency
                          medical trips with care and efficiency.
                        </li>
                        <li>
                          <strong>Advanced Dispatch Technology:</strong> Our
                          cutting-edge dispatch software streamlines trip
                          scheduling, route optimization, and communication
                          between drivers, dispatchers, and healthcare
                          providers.
                        </li>
                        <li>
                          <strong>Customizable Solutions:</strong> We tailor our
                          solutions to accommodate varying trip volumes, service
                          areas, and patient preferences.
                        </li>
                        <li>
                          <strong>Compliance and Regulation:</strong> Our
                          comprehensive compliance management tools ensure
                          adherence to regulatory requirements and industry
                          standards, including HIPAA and ADA guidelines.
                        </li>
                        <li>
                          <strong>Efficient Trip Coordination:</strong> Our
                          dispatch solutions optimize trip scheduling and
                          routing to minimize wait times and maximize
                          efficiency.
                        </li>
                        <li>
                          <strong>Cost-Effective Outsourcing:</strong> By
                          outsourcing NEMT dispatch operations to us, healthcare
                          providers can reduce administrative burdens and
                          operational costs by 70%.
                        </li>
                        <li>
                          <strong>Patient-Centered Care:</strong> We prioritize
                          patient needs and preferences, ensuring a positive
                          transportation experience that contributes to overall
                          patient satisfaction and well-being.
                        </li>
                        <li>
                          <strong>Real-time Monitoring:</strong> Our advanced
                          tracking and monitoring capabilities provide real-time
                          visibility into trip status and vehicle location.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/neom3.webp"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/NonEmergency2.png"
                        alt="image"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Quality and Safety</strong>
                        </li>
                        <li>
                          <strong>Patient Confidentiality</strong>
                        </li>
                        <li>
                          <strong>Reliability and Dependability</strong>
                        </li>
                        <li>
                          <strong>Continuous Improvement</strong>
                        </li>
                        <li>
                          <strong>Collaborative Partnerships</strong>
                        </li>
                      </ul>
                      <p>
                        By partnering with us, healthcare providers can
                        streamline transportation logistics, improve patient
                        access to care, and enhance operational efficiency. Join
                        us in making a difference in the lives of those in need.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage2;
