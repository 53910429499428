import ItodoImage from "../../../elements/itodo-img";
import SectionCounter1 from "./section-counter1";

function SectionAboutCompany1() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-white sx-about-bx1-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx1-content-wrap">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-media">
                                        <div className="sx-dot-bx">
                                            <ItodoImage src="images/about/dotted-pic.png" alt="" />
                                        </div>
                                        <div className="sx-ab-img-outer">
                                            {/* <div className="sx-ab-img-section-1-wrap">
                                                <div className="sx-ab-img-section-1" style={{ backgroundImage: `url(${publicUrlFor("images/about/left-pic.png")})` }} />
                                            </div> */}
                                            <div className="sx-ab-img-section-2">
                                                <ItodoImage src="images/about/p1.jpeg" alt="Company Image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-content">
                                        <h2 className="sx-title">Leading the Way in World Wide Dispatch Management</h2>
                                        <span className="sx-title-2">Innovative, Reliable, and Efficient</span>
                                        <p>At World Wide Dispatch Management, we provide top-notch logistics and dispatch solutions tailored to meet the needs of businesses across various industries. Our commitment to excellence and customer satisfaction sets us apart in the logistics sector.</p>
                                        <div className="progress">
                                            <div className="progress-bar sx-bg-primary" role="progressbar" style={{ width: '99%' }} aria-valuenow={99} aria-valuemin={0} aria-valuemax={100}>
                                                <span>99.00% On-Time Delivery</span>
                                            </div>
                                        </div>
                                        <div className="row sx-about-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <p>Comprehensive logistics services designed to streamline your operations.</p>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <p>Advanced dispatch management ensuring efficiency and reliability.</p>
                                            </div>
                                        </div>
                                        <div className="row sx-about-icon-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    {/* <div className="sx-media">
                                                        <i className="flaticon-car" />
                                                    </div> */}
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">World Wide Dispatch Management</span>
                                                        <span className="sx-icon-bx-title-info">10+ Projects</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    {/* <div className="sx-media">
                                                        <i className="flaticon-download" />
                                                    </div> */}
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">Dispatch Services</span>
                                                        <span className="sx-icon-bx-title-info">20+ Projects</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* COUNTER START */}
                        <SectionCounter1 />
                        {/* COUNTER END */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionAboutCompany1;
