function SectionTestimonials3() {
    return (
        <>
            <div className="section-full  p-t110 p-b80 sx-about-bx4-outer" style={{ backgroundColor: '#291f3b' }}>
                <div className="container">
                    <div className="section-content">
                        {/*testimonial section */}
                        <div className="sx-testimonial-bx-2  m-b30">
                            {/* TITLE START */}
                            <div className="section-head center">
                                <div className="sx-head-s-title" style={{ color: '#fff' }}>Our Testimonials</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title" style={{ color: '#fff' }}>What Clients Say?</h2>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="owl-carousel testimonial-section-3 owl-btn-vertical-center">
                                {/*One block*/}
                                <div className="item">
                                    <div className="testimonial-3  hover-animation-1 sx-bg-light" style={{
                                        minHeight: '450px'
                                    }} >
                                        <div className="testimonial-detail">
                                            <div className="testimonial-user-info">
                                                <span className="testimonial-position">CEO, Taxi Services Inc.</span>
                                                <h4 className="testimonial-name">Oliver Eliah</h4>
                                            </div>
                                        </div>
                                        <div className="testimonial-text">
                                            <p>World Wide Dispatch Management has significantly improved our dispatch efficiency. Their 24/7 services ensure our drivers are always on the move, and our customers receive timely and professional services.</p>
                                        </div>
                                        <div className="testimonial-footer">
                                            <div className="testimonial-quote sx-text-primary">
                                                <i className="flaticon-quote" />
                                            </div>
                                            <ul className="sx-rating-st1">
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                            </ul>
                                            <span className="sx-review-count">(5 Reviews)</span>
                                        </div>
                                    </div>
                                </div>
                                {/*One two*/}
                                <div className="item" >
                                    <div className="testimonial-3  hover-animation-1 sx-bg-light" style={{
                                        minHeight: '450px'
                                    }} >
                                        <div className="testimonial-detail">
                                            <div className="testimonial-user-info">
                                                <span className="testimonial-position">Operations Manager, Trucking Company</span>
                                                <h4 className="testimonial-name">Delilah Brooklyn</h4>
                                            </div>
                                        </div>
                                        <div className="testimonial-text">
                                            <p>We rely on World Wide Dispatch Management for all our truck dispatch services. Their expertise and dedication have been instrumental in optimizing our operations and increasing our overall productivity.</p>
                                        </div>
                                        <div className="testimonial-footer">
                                            <div className="testimonial-quote sx-text-primary">
                                                <i className="flaticon-quote" />
                                            </div>
                                            <ul className="sx-rating-st1">
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                            </ul>
                                            <span className="sx-review-count">(5 Reviews)</span>
                                        </div>
                                    </div>
                                </div>
                                {/*One three*/}
                                <div className="item">
                                    <div className="testimonial-3  hover-animation-1 sx-bg-light" style={{
                                        minHeight: '450px'
                                    }} >
                                        <div className="testimonial-detail">
                                            <div className="testimonial-user-info">
                                                <span className="testimonial-position">CEO, NEMT Services</span>
                                                <h4 className="testimonial-name">Emily Davis</h4>
                                            </div>
                                        </div>
                                        <div className="testimonial-text">
                                            <p>World Wide Dispatch Management has been a game-changer for our NEMT services. Their reliable and efficient dispatch solutions have helped us provide better care to our clients.</p>
                                        </div>
                                        <div className="testimonial-footer">
                                            <div className="testimonial-quote sx-text-primary">
                                                <i className="flaticon-quote" />
                                            </div>
                                            <ul className="sx-rating-st1">
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                            </ul>
                                            <span className="sx-review-count">(5 Reviews)</span>
                                        </div>
                                    </div>
                                </div>
                                {/*One four*/}
                                <div className="item">
                                    <div className="testimonial-3  hover-animation-1 sx-bg-light" style={{
                                        minHeight: '450px'
                                    }} >
                                        <div className="testimonial-detail">
                                            <div className="testimonial-user-info">
                                                <span className="testimonial-position">Founder, Limo Dispatch Services</span>
                                                <h4 className="testimonial-name">Jane Smith</h4>
                                            </div>
                                        </div>
                                        <div className="testimonial-text">
                                            <p>The team at World Wide Dispatch Management is exceptional. They handle all our limo dispatch needs seamlessly, allowing us to focus on providing top-notch service to our clients.</p>
                                        </div>
                                        <div className="testimonial-footer">
                                            <div className="testimonial-quote sx-text-primary">
                                                <i className="flaticon-quote" />
                                            </div>
                                            <ul className="sx-rating-st1">
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                            </ul>
                                            <span className="sx-review-count">(5 Reviews)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionTestimonials3;