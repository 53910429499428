import { publicUrlFor } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";
import "./section-why-choose-us1.css";
function SectionWhyChooseUs1() {
  return (
    <>
      <div
        className="section-full p-t110 p-b80 sx-why-chooseus-outer sx-bg-light bg-cover"
        style={{
          // backgroundImage: `url(${publicUrlFor("images/background/bg-1.jpg")})`
          backgroundColor: "#291f3b",
        }}
      >
        <div className="container">
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title" style={{ color: "#fff" }}>
              Why Choose Us
            </div>
            <div className="sx-head-l-title">
              <h2 className="sx-title" style={{ color: "#fff" }}>
                Our Work Services
              </h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="sx-icon-bx-wrap">
              <div
                className="row"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {/* Block one */}
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "/images/services/images/texi1.webp"
                          )}
                          alt="NEMT Dispatch Outsourcing Solutions"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Taxi-Dispatch-Outsourcing-Solutions">
                          <h4 className="sx-tilte">
                            Taxi Dispatch Outsourcing Solutions
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Optimizing taxi dispatch operations to ensure
                          efficient and reliable service.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Repeat the same structure for each block */}
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor("images/services/images/limo1.webp")}
                          alt="NEMT Dispatch Outsourcing Solutions"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Limo-Dispatch-Outsourcing-Solutions">
                          <h4 className="sx-tilte">
                            Limo Dispatch Outsourcing Solutions
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Providing premium dispatch solutions for limousine
                          services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "/images/services/images/neom2.webp"
                          )}
                          alt="NEMT Dispatch Outsourcing Solutions"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Non-Emergency-Medical-Transportation-(NEMT)-Dispatch-Outsourcing">
                          <h4 className="sx-tilte">
                            NEMT Dispatch Outsourcing Solutions
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Specialized dispatch services for non-emergency
                          medical transportation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor("images/services/images/truck2.webp")}
                          alt="Truck Dispatch Services"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Truck-Dispatch-Services">
                          <h4 className="sx-tilte">Truck Dispatch Services</h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Efficient dispatch solutions for trucking and freight
                          services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "images/services/images/website2.webp"
                          )}
                          alt="Website Development Services"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Website-Development-Services">
                          <h4 className="sx-tilte">
                            Website Development Services
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Professional website development tailored to your
                          business needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "images/services/images/sm1.webp"
                          )}
                          alt="Social Media Marketing and Account Management"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Social-Media-Marketing-and-Account-Management">
                          <h4 className="sx-tilte">
                            Social Media Marketing and Account Management
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Boost your online presence with our social media
                          marketing services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "images/services/images/call3.webp"
                          )}
                          alt="Voice Service for Inbound and Outbound Call Center Services"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Voice-Service-for-Inbound-and-Outbound-Call-Center-Services">
                          <h4 className="sx-tilte">
                            Voice Service for Inbound and Outbound Call Center
                            Services
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Reliable voice services for your call center
                          operations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionWhyChooseUs1;
