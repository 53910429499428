import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import SectionTestimonials2 from "./section-testimonials2";

export function SectionWhyChooseUs3Inner() {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <div className="sx-ab4-img-outer">
                            <div className="sx-ab4-media">
                                <ItodoImage src="images/services/images/star.webp" alt="media" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title">Why Choose World Wide Dispatch Management?</h2>
                        <p>At World Wide Dispatch Management, we specialize in streamlining operations, enhancing efficiency, and providing top-notch service. Here’s why you should choose us:</p>
                        <ul className="sx-arrow-circle-checked">
                            <li>Customized dispatch solutions to meet your unique requirements</li>
                            <li>Round-the-clock customer support and service availability</li>
                            <li>Skilled and professional team with extensive experience</li>
                            <li>State-of-the-art technology and tools for effective management</li>
                            <li>Competitive pricing with no hidden fees</li>
                        </ul>
                        <NavLink to="/about-us" className="site-button sx-btn-primary icon sx-btn-lg">
                            <i className="fa fa-long-arrow-right" />
                            Learn More
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionWhyChooseUs3() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUs3Inner />
                        </div>
                        {/* Testimonial Section */}
                        <SectionTestimonials2 />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionWhyChooseUs3;
