import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/resize taxi header.webp"
              alt="image"
              className="image"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Taxi Dispatch Outsourcing Solutions
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title">Why Choose Us</div>
                <h2 className="sx-title">
                  Taxi Dispatch Outsourcing Solutions
                </h2>
                <div className="sx-head-l-title">
                  <p>
                    At World Wide Dispatch Management, we understand the demands
                    of the modern transportation industry. Our Taxi Dispatch
                    Outsourcing solutions offer a comprehensive suite of
                    services tailored to meet the needs of taxi companies,
                    ride-hailing services, and transportation businesses
                    worldwide. With our expertise and cutting-edge technology,
                    we empower our clients to streamline operations, enhance
                    efficiency, and deliver exceptional customer experiences.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Offerings:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>24/7 Dispatch Services:</strong> Our dedicated
                          team of dispatch professionals operates
                          round-the-clock to ensure seamless coordination and
                          timely dispatching of vehicles.
                        </li>
                        <li>
                          <strong>Advanced Dispatch Software:</strong> Benefit
                          from our state-of-the-art dispatch software equipped
                          with advanced features such as real-time tracking,
                          automated scheduling, and intelligent routing
                          algorithms.
                        </li>
                        <li>
                          <strong>Customizable Solutions:</strong> We understand
                          that every business is unique. Our solutions are
                          customizable to adapt to your specific requirements,
                          whether you operate a small fleet or manage a
                          large-scale taxi network.
                        </li>
                        <li>
                          <strong>Scalability:</strong> As your business grows,
                          our solutions scale with you. Whether you need to add
                          new vehicles, expand into new markets, or accommodate
                          seasonal fluctuations in demand, we've got you
                          covered.
                        </li>
                        <li>
                          <strong>Cost Efficiency:</strong> By outsourcing your
                          taxi dispatch operations to us, you can reduce
                          overhead costs by 70% associated with maintaining an
                          in-house dispatch center while enjoying the benefits
                          of a professional and efficient service.
                        </li>
                        <li>
                          <strong>Enhanced Customer Experience:</strong> With
                          our streamlined dispatch processes and advanced
                          technology, we ensure that your customers receive
                          prompt and reliable service, leading to increased
                          satisfaction and loyalty.
                        </li>
                        <li>
                          <strong>Access to Real-time Data:</strong> Gain
                          valuable insights into your operations with our
                          comprehensive reporting and analytics tools. Track key
                          performance metrics, monitor driver behavior, and make
                          data-driven decisions to optimize your business.
                        </li>
                        <li>
                          <strong>Dedicated Support:</strong> Our team is
                          committed to providing exceptional customer support
                          every step of the way. From implementation to ongoing
                          maintenance, we're here to assist you and address any
                          concerns you may have.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 pt-5">
                      <ItodoImage src="images/services/p1.jpeg" alt="image" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage src="images/services/images/texi2.webp" alt="image" />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h3>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li>
                          <strong>Reliability:</strong> We understand the
                          importance of reliability in the transportation
                          industry. Our dispatch solutions are built to deliver
                          consistent and dependable service, ensuring that your
                          vehicles are always where they need to be, when they
                          need to be there.
                        </li>
                        <li>
                          <strong>Security and Privacy:</strong> Your data
                          security and privacy are our top priorities. We adhere
                          to industry best practices and employ robust security
                          measures to safeguard your sensitive information.
                        </li>
                        <li>
                          <strong>Partnership Approach:</strong> We view our
                          clients as partners and are dedicated to building
                          long-term relationships based on trust, transparency,
                          and mutual success. Your success is our success, and
                          we will work closely with you to achieve your business
                          goals.
                        </li>
                      </ul>
                      <p>
                        With World Wide Dispatch Management's Taxi Dispatch
                        Outsourcing solutions, you can streamline your
                        operations, improve efficiency, and deliver exceptional
                        service to your customers. Partner with us today and
                        experience the difference firsthand.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage;
