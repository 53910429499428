import { NavLink } from "react-router-dom";
import ItodoImage from "../../../elements/itodo-img";

function SectionTeam2() {
    return (
        <>
            <div className="section-full p-t110 sx-bg-light sx-ourteam-outer" style={{ 
                backgroundColor: "#291f3b",
                 }}>
                <div className="container">
                    {/* TITLE START */}
                    {/*Large Title*/}
                    <div className="large-title-block sx-all-white">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                {/* TITLE START */}
                                <div className="section-head left">
                                    <div className="sx-head-s-title">Team Member</div>
                                    <div className="sx-head-l-title">
                                        <h2 className="sx-title">Our World Wide Dispatch Management Special Team Members</h2>
                                    </div>
                                </div>
                                {/* TITLE END */}
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="large-title-info">
                                    <p>At World Wide Dispatch Management, our dedicated team is committed to streamlining operations, improving efficiency, and delivering exceptional service. We leverage our expertise to ensure your dispatch needs are met with the highest standards of professionalism and care.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="owl-carousel sx-our-team-carousel m-b110">
                            {/*One block*/}
                            <div className="item">
                                <div className="our-team-2 white">
                                    <div className="profile-image">
                                        <ItodoImage src="images/our-team5/Miss-Samar.webp" alt=""/>
                                        {/* <div className="icons">
                                            <a href="https://www.facebook.com/"><i className="fa fa-facebook" /></a>
                                            <a href="https://www.instagram.com/"><i className="fa fa-instagram" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-dribbble" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-behance" /></a>
                                        </div> */}
                                    </div>
                                    <div className="figcaption">
                                        <p>Bilangual Arabic and English CSR</p>
                                        <h4 className="sx-title"><NavLink to="/team">Miss-Samar</NavLink></h4>
                                    </div>
                                </div>
                            </div>
                            {/*two block*/}
                            <div className="item">
                                <div className="our-team-2 white">
                                    <div className="profile-image">
                                        <ItodoImage src="images/our-team5/Merlin.webp" alt=""/>
                                        {/* <div className="icons">
                                            <a href="https://www.facebook.com/"><i className="fa fa-facebook" /></a>
                                            <a href="https://www.instagram.com/"><i className="fa fa-instagram" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-dribbble" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-behance" /></a>
                                        </div> */}
                                    </div>
                                    <div className="figcaption">
                                        <p>Bilingual French and English & Hausa CSR</p>
                                        <h4 className="sx-title"><NavLink to="/team">Merlin</NavLink></h4>
                                    </div>
                                </div>
                            </div>
                            {/*three block*/}
                            <div className="item">
                                <div className="our-team-2 white">
                                    <div className="profile-image">
                                        <ItodoImage src="images/our-team5/Miss-Fatiha.webp" alt=""/>
                                        {/* <div className="icons">
                                            <a href="https://www.facebook.com/"><i className="fa fa-facebook" /></a>
                                            <a href="https://www.instagram.com/"><i className="fa fa-instagram" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-dribbble" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-behance" /></a>
                                        </div> */}
                                    </div>
                                    <div className="figcaption">
                                        <p>Bilingual French and English CSR</p>
                                        <h4 className="sx-title"><NavLink to="/team">Miss Fatiha</NavLink></h4>
                                    </div>
                                </div>
                            </div>
                            {/*Four block*/}
                            <div className="item">
                                <div className="our-team-2 white">
                                    <div className="profile-image">
                                        <ItodoImage src="images/our-team5/Kevin.webp" alt=""/>
                                        {/* <div className="icons">
                                            <a href="https://www.facebook.com/"><i className="fa fa-facebook" /></a>
                                            <a href="https://www.instagram.com/"><i className="fa fa-instagram" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-dribbble" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-behance" /></a>
                                        </div> */}
                                    </div>
                                    <div className="figcaption">
                                        <p> Bilingual French, Spanish & English CSR</p>
                                        <h4 className="sx-title"><NavLink to="/team">Kevin</NavLink></h4>
                                    </div>
                                </div>
                            </div>
                            {/*five block*/}
                            <div className="item">
                                <div className="our-team-2 white">
                                    <div className="profile-image">
                                        <ItodoImage src="images/our-team5/Miss-Justine.webp" alt=""/>
                                        {/* <div className="icons">
                                            <a href="https://www.facebook.com/"><i className="fa fa-facebook" /></a>
                                            <a href="https://www.instagram.com/"><i className="fa fa-instagram" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-dribbble" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-behance" /></a>
                                        </div> */}
                                    </div>
                                    <div className="figcaption">
                                        <p>Bilingual French & English & Yoruba CSR</p>
                                        <h4 className="sx-title"><NavLink to="/team">Miss Justine</NavLink></h4>
                                    </div>
                                </div>
                            </div>
                            {/*six two*/}
                            <div className="item">
                                <div className="our-team-2 white">
                                    <div className="profile-image">
                                        <ItodoImage src="images/our-team5/Samuel-Kawame-Bebli.webp" alt=""/>
                                        {/* <div className="icons">
                                            <a href="https://www.facebook.com/"><i className="fa fa-facebook" /></a>
                                            <a href="https://www.instagram.com/"><i className="fa fa-instagram" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-dribbble" /></a>
                                            <a href="https://twitter.com/"><i className="fa fa-behance" /></a>
                                        </div> */}
                                    </div>
                                    <div className="figcaption">
                                        <p> Bilingual French and English CSR</p>
                                        <h4 className="sx-title"><NavLink to="/team">Samuel Kawame Bebli</NavLink></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionTeam2;