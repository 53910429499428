import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import SectionTestimonials2 from "./section-testimonials2";

export function SectionWhyChooseUsInner() {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <div className="sx-ab4-img-outer">
                            <div className="sx-ab4-media">
                                <ItodoImage src="images/services/images/call5.webp" alt="media" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title">Why Partner with World Wide Dispatch Management?</h2>
                        <p>World Wide Dispatch Management excels in delivering unparalleled dispatch solutions, ensuring your business operates smoothly and efficiently. Discover the benefits of choosing us:</p>
                        <ul className="sx-arrow-circle-checked">
                            <li>Tailored dispatch services to meet diverse business needs</li>
                            <li>Round-the-clock customer service for uninterrupted support</li>
                            <li>Expert team with a proven track record in dispatch management</li>
                            <li>State-of-the-art technology for optimal operational performance</li>
                            <li>Cost-effective solutions with clear and honest pricing</li>
                        </ul>
                        <NavLink to="/about-us" className="site-button sx-btn-primary icon sx-btn-lg">
                            <i className="fa fa-long-arrow-right" />
                            Learn More
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionWhyChooseUs() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUsInner />
                        </div>
                        {/* Testimonial Section */}
                        <SectionTestimonials2 />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionWhyChooseUs;
