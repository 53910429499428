import ItodoImage from "../../../elements/itodo-img";
import SectionCounter1 from "./section-counter1";

function SectionAboutCompany2() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-white sx-about-bx1-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx1-content-wrap">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-media">
                                        <div className="sx-dot-bx">
                                            <ItodoImage src="images/about/dotted-pic.png" alt="" />
                                        </div>
                                        <div className="sx-ab-img-outer">
                                            <div className="sx-ab-img-section-2">
                                                <ItodoImage src="images/services/images/call3.webp" alt="Company Image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-content">
                                        <h2 className="sx-title">Expert Solutions in Dispatch Management</h2>
                                        <span className="sx-title-2">Streamlining Operations, Enhancing Efficiency</span>
                                        <p>World Wide Dispatch Management specializes in providing comprehensive dispatch solutions to optimize your business operations. With a commitment to excellence, we ensure seamless and efficient dispatch services tailored to your needs.</p>
                                        <div className="progress">
                                            <div className="progress-bar sx-bg-primary" role="progressbar" style={{ width: '98%' }} aria-valuenow={98} aria-valuemin={0} aria-valuemax={100}>
                                                <span>98.00% Client Satisfaction</span>
                                            </div>
                                        </div>
                                        <div className="row sx-about-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <p>Customized dispatch solutions to meet your specific requirements.</p>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <p>Innovative technologies for efficient and reliable dispatch services.</p>
                                            </div>
                                        </div>
                                        <div className="row sx-about-icon-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">Trusted by Clients</span>
                                                        <span className="sx-icon-bx-title-info">15+ Years of Experience</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">Global Reach</span>
                                                        <span className="sx-icon-bx-title-info">Serving 10+ Countries</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SectionCounter1 />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionAboutCompany2;
