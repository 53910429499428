import { NavLink, useLocation } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";
import { useState } from "react";
import { loadScript } from "../../../globals/constants";
import { useEffect } from "react";
import "./Header.css";

function Header1() {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  function toggleNavClass() {
    setIsActive(!isActive);
  }

  useEffect(() => {
    loadScript("js/mobilenav.js");
  });

  return (
    <>
      <header
        className={
          "header-style1 site-header  mobile-sider-drawer-menu " +
          (isActive ? "active" : "")
        }
      >
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar">
            <div className="container clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to="">
                    <ItodoImage src="images/icon.png" alt="" />
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                className="navbar-toggler collapsed"
                onClick={toggleNavClass}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              {/* EXTRA NAV */}
              <div className="extra-nav">
                <div className="extra-cell">
                  <ul className="list-unstyled social-bx d-flex flex-wrap align-content-center">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=61555253836713">
                        <i className="feather-facebook" />{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/world-wide-dispatch-management/mycompany/">
                        <i className="feather-linkedin" />{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/wwdispatchm/">
                        <i className="feather-instagram" />{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://x.com/wwdispatchm">
                        <i className="feather-twitter" />{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com/wwdispatchm/">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFFklSM2f8zAKlIZGNG_mqm4V136eG7rt-lg&s" alt="pinterest" width={'15px'} height={'15px'}/>
                        {/* <i className="feather-pinterest" />{" "} */}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCIQw6KivHxUkMGKgQgcOCVQ">
                        <i className="feather-youtube" />{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* EXTRA Nav */}
              {/* MAIN NAVIGATION */}
              <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                <ul className=" nav navbar-nav ">
                  <li className={location.pathname === "/" ? "active" : ""}>
                    <NavLink to="">Home</NavLink>
                  </li>
                  <li
                    className={
                      location.pathname.startsWith("/services") ? "active" : ""
                    }
                  >
                    <NavLink to="/services">Services</NavLink>
                    <ul className="sub-menu">
                      <li>
                        <NavLink to="/services/Taxi-Dispatch-Outsourcing-Solutions">
                          Taxi Dispatch Outsourcing Solutions
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Limo-Dispatch-Outsourcing-Solutions">
                          Limo Dispatch Outsourcing Solutions
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Non-Emergency-Medical-Transportation-(NEMT)-Dispatch-Outsourcing">
                          Non-Emergency Medical Transportation (NEMT) Dispatch
                          Outsourcing Solutions
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Truck-Dispatch-Services">
                          Truck Dispatch Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Website-Development-Services">
                          Website Development Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Social-Media-Marketing-and-Account-Management">
                          Social Media Marketing and Account Management
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Voice-Service-for-Inbound-and-Outbound-Call-Center-Services">
                          Voice Service for Inbound and Outbound Call Center
                          Services
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className={location.pathname === "/team" ? "active" : ""}>
                    <NavLink to="/team">Our Team</NavLink>
                  </li>
                  <li
                    className={location.pathname === "/about-us" ? "active" : ""}
                  >
                    <NavLink to="/about-us">About Us</NavLink>
                  </li>
                  <li
                    className={location.pathname === "/contact-us" ? "active" : ""}
                  >
                    <NavLink to="/contact-us">Contact Us</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header1;
