import { Route, Routes } from "react-router-dom";
import AboutUsPage from "../app/components/company/about-us";
import FAQPage from "../app/components/company/faq";
import IconsPage from "../app/components/company/icons";
import TeamPage from "../app/components/company/team";
import TestimonialsPage from "../app/components/company/testimonials";
import ServicesPage from "../app/components/IT solutions/services/services";
import BlogGridPage from "../app/components/IT blogs/blog-grid";
import BlogListSidebarPage from "../app/components/IT blogs/blog-list-sidebar";
import BlogDetailSidebarPage from "../app/components/IT blogs/blog-detail-sidebar";
import ContactUsPage from "../app/components/contactus/contact-us";
import Home3Page from "../app/components/home/index3";
import ServicesDetailPage2 from "../app/components/IT solutions/services/detail2";
import ServicesDetailPage1 from "../app/components/IT solutions/services/detail1";
import ServicesDetailPage3 from "../app/components/IT solutions/services/detail3";
import ServicesDetailPage4 from "../app/components/IT solutions/services/detail4";
import ServicesDetailPage5 from "../app/components/IT solutions/services/detail5";
import ServicesDetailPage6 from "../app/components/IT solutions/services/detail6";
import TermsAndConditions from "../app/sections/company/term-and-condition";
import ServicesDetailPage from "../app/components/IT solutions/services/detail";

function AppRoutes() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home3Page />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/term-and-condition" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<TermsAndConditions />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/icons" element={<IconsPage />} />
                <Route path="/testimonials" element={<TestimonialsPage />} />
                <Route path="/team" element={<TeamPage />} />
                {/* <Route path="/mission" element={<MissionVisionPage />} /> */}
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/services/Taxi-Dispatch-Outsourcing-Solutions" element={<ServicesDetailPage />} />
                <Route path="/services/Limo-Dispatch-Outsourcing-Solutions" element={<ServicesDetailPage1 />} />
                <Route path="/services/Non-Emergency-Medical-Transportation-(NEMT)-Dispatch-Outsourcing" element={<ServicesDetailPage2 />} />
                <Route path="/services/Truck-Dispatch-Services" element={<ServicesDetailPage3 />} />
                <Route path="/services/Website-Development-Services" element={<ServicesDetailPage4 />} />
                <Route path="/services/Social-Media-Marketing-and-Account-Management" element={<ServicesDetailPage5 />} />
                <Route path="/services/Voice-Service-for-Inbound-and-Outbound-Call-Center-Services" element={<ServicesDetailPage6 />} />
                <Route path="/blogs" element={<BlogGridPage />} />
                <Route path="/blogs/list" element={<BlogListSidebarPage />} />
                <Route path="/blogs/detail-with-sidebar" element={<BlogDetailSidebarPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
            </Routes>
        </>
    )
}

export default AppRoutes;